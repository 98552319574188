// start script for high light header and footer menu
const currentLocation = location.href;
const menuItems = document.querySelectorAll('a');
const menuLength = menuItems.length;
for (let i = 0; i < menuLength; i++) {
  if (menuItems[i].href === currentLocation) {
    menuItems[i].style.color = '#ff9432';
  }
}

  let productDropdown = document.querySelector('#toogle-product');
  let solutionDropdown = document.querySelector('#toogle-solution');
  let productIconElem = document.querySelector('.product-angle-icon');
  let SolutionIconElem = document.querySelector('.solution-angle-icon');
  let dropdownProduct = false;
  let dropdownSolution = false;
  window.addEventListener('click', function (e) {
    if (productDropdown.contains(e.target)) {
      if (dropdownProduct) {
        productIconElem.className = "fa fa-angle-down product-angle-icon"
        dropdownProduct = false
      }
      else {
        productIconElem.className = "fa fa-angle-down product-angle-icon product-angle-icon-rotate"
        dropdownProduct = true
      }
    } else {
      productIconElem.className = "fa fa-angle-down product-angle-icon"
      dropdownProduct = false
    }

    if (solutionDropdown.contains(e.target)) {
      if (dropdownSolution) {
        SolutionIconElem.className = "fa fa-angle-down product-angle-icon"
        dropdownSolution = false
      }
      else {
        SolutionIconElem.className = "fa fa-angle-down product-angle-icon product-angle-icon-rotate"
        dropdownSolution = true
      }
    } else {
      SolutionIconElem.className = "fa fa-angle-down product-angle-icon"
      dropdownSolution = false
    }
  });



// end script for high light header and footer menu
